var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InvoiceView", attrs: { "data-test-id": "invoice-view" } },
    [
      _vm.isLoading
        ? _c("ui-loader", { attrs: { fixed: "", "data-test-id": "loader" } })
        : _vm._e(),
      _c("PageTabs", {
        staticClass: "pb-1",
        attrs: { tabs: _vm.tabs, "has-overview": "" },
      }),
      _c(
        "PageView",
        { attrs: { "is-tab-content": "" } },
        [_c("RouterView")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }