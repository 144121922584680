<script>
import get from 'lodash/get';
import { mapState } from 'vuex';
import { INVOICE_TABS } from '@domains/Invoicing/const/InvoiceTabs';
import { VALIDATION_PATTERN } from '@emobg/web-utils';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageTabs, PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'InvoiceView',

  components: {
    PageTabs,
    PageView,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorId: state => state.operators.active.id,
    }),
  },

  created() {
    this.tabs = INVOICE_TABS;
  },

  async beforeMount() {
    const invoiceIdentification = get(this.$route, 'params.invoiceUuid');

    if (VALIDATION_PATTERN.number.test(invoiceIdentification)) {
      const invoiceUuid = await this.getInvoiceUuidFromId(invoiceIdentification);

      this.isLoading = true;
      window.location = `/invoicing/invoices/${invoiceUuid}/summary/details`;
    }
  },

  methods: {
    async getInvoiceUuidFromId(invoiceId) {
      const response = await this.$algolia.fetchIndex(ALGOLIA_INDEXES.invoices, {
        filters: `cs_operator_fk:${this.activeOperatorId} AND id:${invoiceId}`,
        attributesToRetrieve: ['uuid'],
      });

      return get(response, 'hits[0].uuid');
    },
  },
};
</script>

<template>
  <div
    class="InvoiceView"
    data-test-id="invoice-view"
  >
    <ui-loader
      v-if="isLoading"
      fixed
      data-test-id="loader"
    />

    <PageTabs
      :tabs="tabs"
      has-overview
      class="pb-1"
    />
    <PageView is-tab-content>
      <RouterView />
    </PageView>
  </div>
</template>

<style lang="scss">
.InvoiceView {
  .MuiAlgoliaList {
    padding: 0 !important;
    border: none;
  }
}
</style>
